export const plans = [
  {
    id: 'free',
    name: 'Free',
    priceYearly: 0,
    priceMonthly: 0,
  },
  {
    id: 'essential',
    name: 'Essential',
    priceYearly: 39,
    priceMonthly: 49,
  },
  {
    id: 'standard',
    name: 'Standard',
    priceYearly: 79,
    priceMonthly: 99,
  },
  {
    id: 'advanced',
    name: 'Advanced',
    priceYearly: 169,
    priceMonthly: 199,
  },
]

export const featureGroups = [
    {id: 'usage', label: "Usage"},
    {id: 'features', label: "Smart Features"},
    {id: 'databases', label: "Database"},
    {id: 'snapshots', label: "Snapshot"},
    {id: 'cloud-replication', label: "Cloud Storage Backup"},
    {id: 'saas', label: "SaaS Applications"},
]

export const features = [
  { group: 'usage', label: 'Backup Jobs', helper: 'Number of backups you can configure. We neither limit the number of backup runs, nor the number of storage or server your connect.',
    plans : {free: '1', essential: '20<div class="font-light -mt-3">(then $20/m per 20)</div>', standard: '50<div class="font-light -mt-3">(then $20/m per 20)</div>', advanced: '200<div class="font-light -mt-3">(then $20/m per 20)</div>' }
  },
  { group: 'usage', label: 'Included SimpleStorage (EU or US)*', helper: 'Free storage included where you can save your backups, otherwise you can use your own storage.',
    plans : {free: '-', essential: '30 GB<div class="font-light -mt-3">(then $10/m per 100 GB)</div>', standard: '60 GB<div class="font-light -mt-3">(then $10/m per 100 GB)</div>', advanced: '200 GB<div class="font-light -mt-3">(then $10/m per 100 GB)</div>' }
  },
  { group: 'usage', label: 'Bring your own Storage', helper: 'Connect your storage provider and get unlimited backup storage space. Unlimited number of connected accounts.',
    plans : {free: true, essential: true, standard: true, advanced: true }
  },
  { group: 'usage', label: 'Serverless Data Transfer', helper: 'Leverage our worker infrastructure to process your backups. Unlimited data transfer if you use your own server.',
    plans : {free: '-',  essential: '50 GB<div class="font-light -mt-3">(then $10/m per TB)</div>', standard: '500 GB<div class="font-light -mt-3">(then $10/m per TB)</div>', advanced: '2 TB<div class="font-light -mt-3">(then $10/m per TB)</div>' }
  },




  // Database Backup
  // *********************
  { group: 'databases', label: 'Self-hosted Database Backups', helper: '',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'databases', label: 'SSH Tunneling', helper: 'Keep your databases private and tunnel backups through SSH to get them done',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'databases', label: 'DbaaS Backups', helper: 'Backup any managed database service such as Amazon RDS, DigitalOcean Managed Databases, MongoDB Atlas, etc.',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'databases', label: 'Back Up All Databases at Once', helper: 'Create one backup to dump all MySQL/MongoDB databases at once without creating a backup for each database',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },


  // Backup Features
  // *********************



  // pure backup
  { group: 'features', label: 'Backup Frequency', helper: 'Choose from the pre-set schedule options available or create you very own custom schedule, every hour, or even every few minutes',
    plans : {free: 'Daily', mini: 'Daily Only', essential: 'Up to every 12 hours', standard: 'Up to every 1 hour', advanced: 'Up to every 1 min' }
  },
  { group: 'features', label: 'Backup Encryption', helper: 'Encrypt your files and database backups using AES-256 and your public RSA key',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'features', label: 'Cron Syntax Scheduling', helper: 'You can use crontab syntax for custom backup job scheduling (run a backup Mon-Fri every three hours for example)',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },
  { group: 'features', label: 'Incremental Backups', helper: 'Only back up changed files',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'features', label: 'Backup Streaming', helper: 'Stream upload your file backups on-the-fly to your storage without using any disk space',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },



  { group: 'features', label: 'Advanced Notifications', helper: 'Support Slack, Discord, Webhooks, Pushover',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'features', label: 'Backup Statistics', helper: 'See some useful stats about your backups like upcoming scheduled runs, average time it takes to complete each backup, heads up when a currently running backup is taking more than usual',
    plans : {free: '-',  essential: true, standard: true, advanced: true}
  },

  { group: 'features', label: 'Projects', helper: 'Projects can be used to group and isolate access to backups to selected team members. Useful when managing backups for your customers!',
    plans : {free: false,  essential: false, standard: true, advanced: true }
  },
  { group: 'features', label: 'Team Members', helper: 'Invite team members and share access to your account',
    plans : {free: '-',  essential: '-', standard: true, advanced: true }
  },
  { group: 'features', label: 'Uncompressed Backups', helper: 'Store your backups uncompressed for a speedy restore',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },
  { group: 'features', label: 'Custom Scripts', helper: 'Run certain tasks & custom scripts before and after your backup.',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },

  { group: 'features', label: 'Webhooks & Triggers', helper: 'Programmatically run your backups or trigger calls based on certain events',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },

  { group: 'features', label: 'Backup Automation API', helper: 'Create backups using our API',
    plans : {free: false,  essential: false, standard: false, advanced: true}
  },

  { group: 'features', label: 'Multi-Scheduling', helper: 'Configure multiple schedule and retention per backups (Grandfather-father-son strategies...)',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },
  { group: 'features', label: 'Multi-Storage', helper: 'Store your backups on multiple storage at the same time',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },

  // Snapshots
  // *******************
  { group: 'snapshots', label: 'Support all providers', helper: 'Backup any Droplet, Server, Instance',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'snapshots', label: 'Server Snapshot', helper: 'Backup any Droplet, Server, Instance',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'snapshots', label: 'Volume Snapshot', helper: 'Backup any Volume',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'snapshots', label: 'Unlimited IaaS Accounts', helper: 'Connect as many IaaS accounts, you need, even multiple accounts of the same provider',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'snapshots', label: 'Multi-Regions Replication', helper: 'Transafer your Snapshot to multiple regions. Only for supported providers.',
    plans : {free: false,  essential: false, standard: true, advanced: true}
  },

  // Cloud Replication
  // *******************

  { group: 'cloud-replication', label: 'Support all providers', helper: 'Support all S3 compliant providers out of the box, and all our partners',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'cloud-replication', label: 'Use your own Worker', helper: '',
    plans : {free: true,  essential: true, standard: true, advanced: true}
  },
  { group: 'cloud-replication', label: 'Serverless Replication', helper: '',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'cloud-replication', label: 'Object Limit', helper: 'Maximum number of objects that can be replicated in a job',
    plans : {free: '-',  essential: '250k', standard: '500k', advanced: 'no preset limit'}
  },


  // Saas Replication
  // *******************

  { group: 'saas', label: 'Github Backup', helper: 'Backup for your GitHub repo, gists, accounts, issues, wiki and more.',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'saas', label: 'Gitea Backup', helper: '',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },
  { group: 'saas', label: 'Knack Backup', helper: '',
    plans : {free: false,  essential: true, standard: true, advanced: true}
  },

]
